<style scoped>
    #planning-container {
        background-color: white;
        box-shadow: 0 10px 15px -6px rgb(0 20 40 / 35%);
        height: calc(100vh - 125px);
        padding: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        margin: 30px 30px 0px 30px
    }

    #planning-header {
        display: flex;
        background-color: rgb(240, 243, 246);
        border-bottom: 1px solid rgb(220, 225, 230);
        text-transform: uppercase;
        color: rgb(80, 90, 100);
        height: 42px;
        font-size: 14px;
        font-weight: 500;
    }

    .planning-header-day {
        margin-right: 5px;
    }

    #planning-header > div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
    }

    #planning-header > div:first-child {
        flex: 0 0 60px;
        width: 60px;
    }

    #planning-header > div:not(:last-child) {
        border-right: 1px solid rgb(220, 225, 230);
    }

    #planning-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        width: 100%;
        overflow-x: hidden;
        overflow-y: overlay;
        margin-bottom: -2px;
    }

    #planning-content::-webkit-scrollbar {
        display: none;
    }


    .morning-planning, .afternoon-planning {
        display: flex;
        flex:1 ;
    }

    .morning-planning > div, .afternoon-planning > div {
        flex: 1;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 102px;
        padding: 15px;
        position: relative;
        z-index: 1;
    }

    .morning-planning > div:not(:first-child):hover, .afternoon-planning > div:not(:first-child):hover {
        z-index: 9;
    }

    .morning-planning > div:first-child, .afternoon-planning > div:first-child {
        background-color: rgb(250, 251, 252);
        flex: 0 0 60px;
        width: 60px;
        color: rgb(150, 160, 170);
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        white-space: nowrap;
        padding: 0;
    }

    .morning-planning > div:first-child div, .afternoon-planning > div:first-child div {
        transform: rotate(-90deg);
    }

    #planning-content .morning-planning > div:not(:first-child),
    #planning-content .afternoon-planning > div {
        border-bottom: 1px solid rgb(220, 225, 230);
    }

    .morning-planning > div:not(:first-child), .afternoon-planning > div:not(:first-child) {
        flex-direction: column;
        justify-content: flex-start;
    }

    .morning-planning > div, .afternoon-planning > div {
        border-right: 1px solid rgb(220, 225, 230);
    }

    #planning-header .current-date {
        background: linear-gradient(90deg, #ff6b00, #ff9100);
        color: white;
    }

    #planning-content .current-date {
        background: linear-gradient(90deg, #ff6b0011, #ff910011);
    }

    .task {
        min-height: 60px;
        width: 220px;
        position: relative;
        background-color: white;
        box-shadow: 0 2px 5px -2px rgba(0, 20, 40, 0.4);
        box-sizing: border-box;
        border-radius: 2px;
        margin-bottom: 15px;
        z-index: 10;
        flex-shrink: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 15px 4px;
    }

    .task-name {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 13px;
    }

    .task-name span {
        padding: 0 5px;
    }

    .task-name span:first-child {
        font-weight: 600;
        font-size: 14px;
    }

    .task-quotation-duration {
        font-size: 11px;
        color: #808080;
    }

    .task-name span:nth-child(3) {
        margin-top: 5px;
    }

    .task-name span:nth-child(4), .task-name span:nth-child(5) {
        font-size: 12px;
        color: #505A64;
    }

    #planning-container .task {
        width: 100%;
    }

    .topbar-right-container #topbar-button-container .button:nth-child(1) {
        background: linear-gradient(90deg, #1E71BB, #187EC2);
        border-right: 1px solid #3790CA;
    }

    .topbar-right-container #topbar-button-container .button:nth-child(2) {
        background: linear-gradient(90deg, #187EC2, #0E8AC7);
        border-right: 1px solid #349BCF;
    }

    .topbar-right-container #topbar-button-container .button:nth-child(3) {
        background: linear-gradient(90deg, #0E8AC7, #0796CB);
    }

    .topbar-right-container #topbar-button-container .button {
        border-radius: 0px;
    }

    .topbar-right-container #topbar-button-container .button:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .topbar-right-container #topbar-button-container .button:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    #topbar-button-container {
        display: flex;
    }
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton @click="previousWeek">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    </template>
                </BaseButton>
                <BaseButton @click="resetWeek">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['far', 'circle']" />
                    </template>
                </BaseButton>
                <BaseButton @click="nextWeek">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>
    <div id="planning-container" v-if="tasks !== null">
        <div id="planning-header">
            <div></div>
            <div v-for="(day, index) in tasks.days" :key="index" :class="day.date === dateOfTheDay ? 'current-date' : ''">
                {{ formateDate(day.date, 'EEEE dd/MM') }}
            </div>
        </div>
        <div id="planning-content">
            <div class="morning-planning">
                <div>
                    <div>Matin</div>
                </div>
                <div v-for="(planningDay, planningDayIndex) in tasks.days" :key="'0_' + planningDayIndex" :class="planningDay.date === dateOfTheDay ? 'current-date' : ''">
                    <div class="task" v-for="(task) in planningDay.tasks.morning" :key="task.id" :data-discipline-id="task.discipline.id" :data-affair-id="task.affair.id" @click="onTaskClick">
                        <div class="task-name">
                            <span>{{ task.affair.number }}</span>
                            <span>{{ task.discipline.label }}</span>
                            <span class="task-quotation-duration">{{ task.quotationDuration != null ? `${task.quotationDuration}H prévue${task.quotationDuration > 1 ? 's' : ''}` : '' }}</span>
                            <span>{{ task.spindle.brand }}</span>
                            <span>{{ task.spindle.type }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="afternoon-planning">
                <div>
                    <div>Après-midi</div>
                </div>
                <div v-for="(planningDay, planningDayIndex) in tasks.days" :key="'1_' + planningDayIndex" :class="planningDay.date === dateOfTheDay ? 'current-date' : ''">
                    <div class="task" v-for="(task) in planningDay.tasks.afternoon" :key="task.id" :data-discipline-id="task.discipline.id" :data-affair-id="task.affair.id" @click="onTaskClick">
                        <div class="task-name">
                            <span>{{ task.affair.number }}</span>
                            <span>{{ task.discipline.label }}</span>
                            <span class="task-quotation-duration">{{ task.quotationDuration != null ? `${task.quotationDuration}H prévue${task.quotationDuration > 1 ? 's' : ''}` : '' }}</span>
                            <span>{{ task.spindle.brand }}</span>
                            <span>{{ task.spindle.type }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { format, addDays } from "date-fns";
    import { fr } from 'date-fns/locale'
    import BaseButton from '../../components/Base/BaseButton.vue';
    import CustomTopbar from '../../components/Topbar/CustomTopbar.vue';

    export default {
        name: 'TabletPlanningTasks',
        components: {
            BaseButton,
            CustomTopbar
        },
        data() {
            return {
                tasks: null,
                dateOfTheDay: format(new Date(), 'yyyy-MM-dd'),
                currentDate: new Date()
            }
        },
        mounted() {
            this.loadPlanning();
        },
        methods: {
            formateDate(date, strFormat) {
                return format(new Date(date), strFormat, {locale: fr});
            },
            loadPlanning(callback) {
                axios
                .get(`planning/list?startedDate=${format(this.currentDate, 'yyyy-MM-dd')}&technicianId=${this.$store.state.authentication.user.id}`, { 
                    showPreloader: false, 
                    toastError: true,
                })
                .then((result) => {
                    if (typeof callback === 'function') callback();
                    // Comme la variable "tasks" peut-être en désaccord avec la vue (qui est draggable), on force son rendu de cette façon
                    this.tasks = [];
                    this.$nextTick(() => {
                        this.tasks = result.data.length > 0 ? result.data[0] : null;
                    });
                })
                .catch(() => {});
            },
            previousWeek() {
                this.currentDate = addDays(this.currentDate, -7);
                this.loadPlanning();
            },
            nextWeek() {
                this.currentDate = addDays(this.currentDate, 7);
                this.loadPlanning();
            },
            resetWeek() {
                this.currentDate = new Date();
                this.loadPlanning();
            },
            onTaskClick(event) {
                document.location = `/pointage?action=addClockingTask&disciplineId=${event.currentTarget.dataset.disciplineId}&affairId=${event.currentTarget.dataset.affairId}`;
            }
        }
    }
</script>